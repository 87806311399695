import React from 'react';

import { AuthorPostGrid } from '../components/AuthorPostGrid';
import { Avatar } from '../components/Avatar/Avatar';
import BigBanner from '../components/Banners/BigBanner';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';

import style from '../styles/pages/author.scss';

import cn from 'classnames';
import { graphql } from 'gatsby';

interface IAuthorProps {
  data: {
    ghostAuthor: {
      slug: string;
      name: string;
      cover_image: string;
      profile_image: string;
      website: string;
      bio: string;
      location: string;
      facebook: string;
      twitter: string;
    };
    allGhostPost: any;
    authorsBioFullJson: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */
const Author = ({ data, location, pageContext }: IAuthorProps) => {
  const author = data.ghostAuthor;
  const posts = data.allGhostPost.edges;

  // Note, some authors have no full bio.
  let { bio: authorBio } = data.authorsBioFullJson || {};
  if (!authorBio) {
    authorBio = author.bio;
  }

  return (
    <>
      <MetaData data={data} location={location} />
      <Layout headerClassName={style.header}>
        <section className={style.authorPage}>
          <div className={cn('container', style.container)}>
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                {pageContext.humanPageNumber === 1 && (
                  <div className={style.authorWrapper}>
                    <div className="row">
                      <div className="col-12 col-md-auto">
                        <Avatar
                          className={style.profileImage}
                          profile_image={author.profile_image}
                          name={author.name}
                        />
                        <p className={cn('d-none d-md-block', style.totalCount)}>
                          Posts: {data.allGhostPost.totalCount}
                        </p>
                      </div>
                      <div className={cn('col-12 col-md order-md-first', style.profileInfo)}>
                        <span>Author</span>
                        <h1>{author.name}</h1>
                        {author.bio && <p dangerouslySetInnerHTML={{ __html: authorBio }} />}
                        <p className={cn('d-md-none', style.totalCount)}>
                          Posts: {data.allGhostPost.totalCount}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <h2 className={style.authorName}>
                  {author.slug}
                  's posts
                </h2>
                <AuthorPostGrid posts={posts} />
              </div>
            </div>
            <Pagination pageContext={pageContext} />
          </div>
        </section>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Author;

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
    }
    authorsBioFullJson(slug: { eq: $slug }) {
      bio
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
