import React from 'react';

import { IPostCard, PostCard } from './PostCard';

import style from './AuthorPostGrid.scss';

interface IProps {
  posts: Array<{
    node: IPostCard;
  }>;
}

export const AuthorPostGrid = ({ posts }: IProps) => (
  <div className={style.default}>
    {posts.map(({ node }) => (
      <PostCard key={node.id} post={node} />
    ))}
  </div>
);
